import { createTheme, styled } from '@mui/material/styles';
import { Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
      contrastText: '#000',
    },
    inputs: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#000',
    },
    secondary: {
      main: '#212121',
      light: '#2e7d32',
      dark: '#1b5e20',
      contrastText: '#fff',
    },
    error: { main: '#c62828', contrastText: '#c62828' },
  },
  typography: { fontSize: 16 },
});

export const quantityInput = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          sx: {
            height: '30px',
            textAlign: 'center',
            fontSize: '20px',
            borderRadius: '0px',
          },
        },
      },
    },
  },
});

export function BtnHeader({ children }) {
  return (
    <Button
      sx={{
        height: '50px',
        width: '300px',
        fontSize: '25px',
        textTransform: 'none',
        boxShadow: '0 0 10px',
      }}
      variant='text'
      color='primary'
    >
      {children}
    </Button>
  );
}

export function BtnShop({ disabled, onClick, children }) {
  return (
    <Button
      sx={{
        height: '80px',
        width: '250px',
        fontSize: '25px',
        textTransform: 'none',
        boxShadow: '0 0 10px',
      }}
      variant='outlined'
      color='primary'
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export function BtnAssortment({ onClick, children }) {
  return (
    <Button
      sx={{
        height: '40px',
        width: '90%',
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        fontSize: '20px',
        textTransform: 'none',
        boxShadow: '0 0 5px',
      }}
      variant='outlined'
      color='primary'
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export function BtnClearCart({ disabled, onClick, children }) {
  return (
    <Button
      sx={{
        height: '80px',
        width: '250px',
        fontSize: '25px',
        textTransform: 'none',
        boxShadow: '0 0 5px',
      }}
      variant='outlined'
      color='error'
      disabled={disabled}
      onClick={onClick}
    >
      <b> {children}</b>
    </Button>
  );
}

export function BtnCartCleaning({ disabled, onClick, children }) {
  return (
    <Button
      sx={{
        height: '60px',
        width: '300px',
        position: 'absolute',
        bottom: '0',
        right: '320px',
        fontSize: '20px',
        textTransform: 'none',
        borderRadius: '10px',
        boxShadow: '0 0 5px',
      }}
      variant='outlined'
      color='error'
      disabled={disabled}
      onClick={onClick}
    >
      <b> {children}</b>
    </Button>
  );
}

export function BtnSubmitOrder({ disabled, onClick, children }) {
  return (
    <Button
      sx={{
        height: '60px',
        width: '250px',
        position: 'absolute',
        bottom: '0',
        right: '0',
        fontSize: '25px',
        textTransform: 'none',
        borderRadius: '10px',
        boxShadow: '0 0 5px',
      }}
      variant='outlined'
      color='primary'
      disabled={disabled}
      onClick={onClick}
    >
      <b>{children}</b>
    </Button>
  );
}

export function BtnIncrease({ onClick }) {
  return (
    <Button
      sx={{
        height: '40px',
        width: '40px',
        fontSize: '25px',
        textTransform: 'none',
        borderRadius: '0px',
        borderTopRightRadius: '10px',
        padding: '0px',
        minWidth: '40px',
      }}
      variant='outlined'
      color='inputs'
      onClick={onClick}
    >
      <b>▲</b>
    </Button>
  );
}

export function BtnDecrease({ onClick }) {
  return (
    <Button
      sx={{
        height: '40px',
        width: '40px',
        fontSize: '25px',
        textTransform: 'none',
        borderRadius: '0px',
        borderBottomRightRadius: '10px',
        padding: '0px',
        minWidth: '40px',
      }}
      variant='outlined'
      color='inputs'
      onClick={onClick}
    >
      <b>▼</b>
    </Button>
  );
}

export function BtnDelete({ onClick }) {
  return (
    <Button
      sx={{
        height: '40px',
        width: '40px',
        fontSize: '25px',
        textTransform: 'none',
        borderRadius: '0px',
        padding: '0px',
        minWidth: '40px',
        position: 'absolute',
        top: '10px',
        right: '10px',
      }}
      color='error'
      onClick={onClick}
    >
      <DeleteIcon sx={{ height: '40px', width: '40px' }} />
    </Button>
  );
}

export function InputUserInfo({ text, value, onChange, disabled }) {
  return (
    <TextField
      sx={{
        width: '600px',
      }}
      variant='outlined'
      color='inputs'
      label={text + ':'}
      placeholder={`Введіть ${text.toLowerCase()}`}
      value={value}
      onChange={onChange}
      disabled={disabled}
    ></TextField>
  );
}

const QuantityInput = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '0px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
}));

export function InputCartQuantity({ value, onChange }) {
  return (
    <QuantityInput
      type='number'
      color='inputs'
      value={value}
      onChange={onChange}
      onFocus={(e) => e.target.select()}
      inputProps={{
        style: {
          fontSize: 30,
          boxSizing: 'border-box',
          width: '250px',
          height: '80px',
          textAlign: 'center',
          maxLength: 2,
        },
      }}
    />
  );
}
